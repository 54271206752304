@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap");

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-header-cover {
  transition: all 0.3s ease;
}

.nav-sticky {
  position: sticky;
  top: 10px;
  width: 100%;
  z-index: 1000;
  animation: fadeInDown 0.5s ease;
}

.nav-sticky.active {
  transform: translateY(0);
  opacity: 1;
  box-shadow: none;
  display: flex;
  justify-content: center;
  width: 100%;
}

.main-header-cover nav {
  background-color: #6696e7 !important;
}

button.Menu-bar-btn.navbar-toggler {
  background-color: white;
}

.App {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  background-color: #ffff;
  margin-top: -14px;
  padding-top: 15px;
  /* background-image: url(./Assets/bg-claims.png); */
}

.notification-box {
  margin-right: 15px;
}

.nav-header {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
  width: 100%;
}

/* .custom-nav-header {
  width: 1100px;
} */

.notification-box svg {
  color: white;
  font-size: 28px;
}

span.Brand-title {
  margin-left: 34px;
  font-family: "Lora", serif;
  font-weight: 700;
  letter-spacing: 0.1px;
}

/* Dummy Logo */
img.broker-logo {
  max-width: 90px;
  max-height: 55px;
  /* mix-blend-mode: lighten; */
}

a.navbar-brand:hover {
  color: white;
}

img.broker-logo-inside {
  width: 80px;
  border-radius: 15px;
  /* mix-blend-mode: darken; */
}

span.nav-icon-box {
  margin-right: 15px;
  width: 35px;
  height: 35px;
  background-color: white;
  display: inline-block;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.offcanvas-body {
  padding: 2px !important;
}

span.nav-icon-box img {
  width: 65%;
}

a.nav-links.active {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-weight: 600;
}

.offcanvas-header button.btn-close {
  background-color: #0c178f3b;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  color: white !important;
  border: 1px solid black;
}

/* Register Form Design */

button.Next-btn {
  padding: 5px 20px;
  border-radius: 7px;
  border: none;
  background-color: #151065;
  color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.form-main-cover-wrapper {
  padding: 25px 34px;
  /* border-radius: 18px; */
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* padding-bottom: 50px; */
}

.register-left-background-cover img {
  width: 77%;
  margin: 0 auto;
  display: flex;
  margin-bottom: -180px;
}

.register-form-btn {
  padding: 6px 24px;
  border-radius: 10px;
  background-color: cornflowerblue;
  color: white;
}

.form-input-box .form-label {
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: 500;
}

.form-input-box input::placeholder {
  font-size: 13px;
  color: rgba(105, 105, 105, 0.792);
}

.form-input-box input.form-control {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 9px 13px;
  border-radius: 8px;
  border: 1px solid #00000021;
}

.register-main-form-cover {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-left-background-cover {
  width: 100%;
  background-color: #a066ed;
  height: 100%;
}

a.nav-links.activeNav-link {
  background-color: #6696e7;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  color: white;
  font-weight: bold;
  border-radius: 8px;
}

/* Login Page Starts */

.login-banner-box {
  position: relative;
  bottom: 8px;
}

.login-banner-box img {
  width: 105%;
  margin-bottom: -12px;
}

.generate-otp-btn-cover button {
  padding: 5px 15px;
  border-radius: 5px;
  background: #8061cf;
  color: white;
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-size: 16px;
}

.login-left-bg {
  position: absolute;
  left: 0;
  width: 300px;
}

.login-left-bg img {
  width: 100%;
}

.login-right-bg img {
  width: 100%;
}

.broker-logo-box img {
  width: 330px;
  margin: 0 auto;
  display: flex;
}

.login-form-main-cover {
  display: flex;
  overflow-y: hidden;
  align-items: center;
  min-height: 100vh;
  padding: 55px;
  background-image: url("./Assets/login-main-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-content-box-cover form {
  padding: 20px 25px;
  background: white;
  border-radius: 10px;
  width: 400px;
}

.login-form-cover {
  width: 65%;
  margin: 0 auto;
  border: 1px solid #0000002e;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-image: url(./Assets/main-login-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.login-content-box-cover form h3 {
  color: #151065;
}

.login-form-content-wrapper {
  height: 400px;
  color: white;
  background: linear-gradient(45deg,
      rgba(245, 66, 102, 0.9),
      rgba(56, 88, 249, 0.9));
}

.text-repo-pro {
  display: flex !important;
  align-items: center !important;
}

span.logout-icons svg {
  font-size: 17px;
}

span.logout-icons img {
  width: 20px;
}

.text-repo-pro p {
  color: black;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
}

.login-header {
  padding: 40px;
}

.login-form-main-cover-wrapper {
  padding: 25px;
  padding-right: 30px;
  padding-left: 10px;
}

button.loginbtn {
  padding: 8px 30px;
  margin-top: 9px;
  border-radius: 9px;
  background: linear-gradient(45deg,
      rgba(245, 66, 102, 0.9),
      rgba(56, 88, 249, 0.9));
  color: white;
  border: none;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* Policy Type Tabs */
.policy-type-tab-main-cover {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  margin-top: 15px;
  padding: 45px 0 15px;
}

.policy-tab-box-cover {
  margin-right: 20px;
}


.tab-box {
  margin: 0px 15px;
  border-radius: 12px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
  border: none;
  text-align: center;
  margin-bottom: 10px;
  background-color: white;
  border: 2px solid transparent;
  width: 80px;
  display: flex;
  height: 80px;
  border-radius: 50%;
  align-items: center;
}


.policy-tab-box-cover h5 {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.policytype-img-box img {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  width: 60%;
}

.active-tab-box {
  border: 2px solid rgb(156 145 225) !important;
  transition: all 0.3s ease-in;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.active-tab-box img {
  filter: grayscale(0);
  opacity: 1;
}

.active-tab-box h5 {
  color: black;
}

/* Escalation Matrix / Contact Us */
.contact-us-main-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.contact-us-content img {
  width: 250px;
}

.contact-us-details-box {
  background: #ffffff 0% 0%;
  box-shadow: 0px 3px 6px #2fdaca93;
  padding: 19px;
  margin-top: 35px;
  position: relative;
  padding-top: 40px;
  padding-bottom: 15px;
  border-radius: 14px;
  font-size: 15px;
  overflow: hidden;
}

.contact-us-user {
  display: flex;
}

.user-image img {
  width: 20px;
}

.user-text p {
  font-weight: 500;
}

.user-text {
  margin-left: 15px;
  margin-top: 3px;
}

.user-text span {
  font-weight: 600;
  margin-left: 5px;
}

.phone-image img {
  width: 20px;
}

.phone-text p {
  font-weight: 500;
}

.phone-text {
  margin-left: 15px;
  margin-top: 3px;
}

.phone-text span {
  font-weight: 600;
  margin-left: 5px;
  color: #007e8b;
}

.email-image img {
  width: 20px;
}

.email-text p {
  font-weight: 500;
}

.email-text {
  margin-left: 15px;
  margin-top: 3px;
}

.email-text span {
  font-weight: 600;
  margin-left: 5px;
}

.contact-us-green-box {
  position: absolute;
  right: 0;
  top: 0;
  /* background: url(../covrage-image/red-box.png); */
}

.contact-us-green-box h3 {
  position: relative;
  top: 0px;
  right: 22px;
  font-size: 17px;
  /* color: white; */
  font-weight: 600;
  /* border: 1px solid black; */
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 8px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.contact-us-green-box img {
  position: absolute;
  top: -8px;
  right: 10px;
  width: 78px;
}

.network-main-header {
  width: 60%;
  margin: 0 auto;
}

/* Members Covered Styles */
.members-download-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 15px;
}

.members-download-card button {
  background: #3386aa;
  border-radius: 12px;
  border: none;
  color: white;
  /* padding: 15px 30px; */
}

.members-download-card button img {
  width: 15px;
}

.members-download-card button span {
  margin-left: 17px;
  font-weight: 500;
}

.members-tpa-logo img {
  width: 120px;
}

.member-card-logo img {
  width: 75px;
}

.member-card-header-details h4 {
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: 600;
  color: #021b8d;
}

.members-card-header {
  padding-bottom: 18px;
  border-bottom: 2px solid #eaea33;
  margin-bottom: 12px;
}

.members-date-text li {
  list-style-type: none;
  margin: 6px 0px;
  font-size: 14px;
}

.members-details-card {
  box-shadow: 0px 3px 6px #70d3ff96;
  padding: 15px 25px;
  border-radius: 25px;
  margin-top: 20px;
  background-color: white;
  border-bottom: 5px solid #152020a8;
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
}

.member-card-header-details p {
  font-weight: 600;
  font-size: 14px;
}

.members-main-cover-wrapper {
  margin-top: 45px;
}

.claim-type-radio-box {
  margin-bottom: 15px;
}

.separater {
  height: 0px;
  background-color: #0000001c;
  width: 60%;
  padding: 0.5px;
  margin: 25px 0px;
  margin: 0 auto;
  margin-bottom: 15px;
}

/* Policy Details Starts ====================== */


.policy-details-content-box {
  display: flex;
  align-items: center;
  background: #ffffff 0% 0%;
  border-radius: 15px;
  border: 1px dashed #6696e7;
  width: 85%;
  margin: 0 auto;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.policy-details-content-box.box1 {
  margin-left: 18px;
  margin-right: -18px;
}

.policy-details-image-box {
  background: #ffffff 0% 0%;
  box-shadow: 0px 3px 6px #70d3ff8c;
  border: 1px solid #4bc4f9;
  border-radius: 10px;
  padding: 12px 15px;
  margin-left: -23px;
}

.policy-details-image-box img {
  width: 23px;
  padding: 1px;
}

.policy-details-text-box {
  padding: 20px 13px 10px 20px;
  height: 100%;
}

.policy-details-text-box h4 {
  font-size: 18px;
  font-weight: 600;
  color: #007e8b;
  text-transform: capitalize;
}

.policy-details-text-box p {
  font-size: 14px;
  font-weight: 500;
}

.policy-details-contact-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.policy-details-contact-button button {
  padding: 12px 40px;
  background: #ffffff 0% 0%;
  border: 2px solid #007e8b;
  border-radius: 12px;
}

.policy-details-contact-button button img {
  width: 25px;
}

.policy-details-contact-button button span {
  margin-left: 12px;
  color: #007e8b;
  font-size: 16px;
  font-weight: 600;
}

/* Download Form Starts ========================== */
.download-forms-banner-img img {
  width: 70%;
  margin: 0 auto;
  display: flex;
  margin-bottom: 45px;
}

.downoad-forms-main-cover h5 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 40px;
  color: #555555;
}

.download-forms-list-cover .download-main-list-box {
  list-style: none;
  padding: 0px;
  margin-top: 25px;
}

.download-form-list-header {
  display: flex;
  align-items: center;
}

li.download-form-list {
  display: flex;
  justify-content: space-between;
}

.pdf-icon-box {
  margin-right: 14px;
  width: 35px;
}

.pdf-icon-box img {
  width: 100%;
}

.download-icon-box {
  width: 30px;
}

.download-icon-box img {
  width: 100%;
}

li.download-form-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 16px;
  width: 95%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #70d3ff8c;
  border-radius: 10px;
  margin: 0 auto;
  margin-bottom: 15px;
}

.download-forms-banner-img img {
  width: 70%;
  margin: 0 auto;
  display: flex;
  margin-bottom: 45px;
  /* margin-top: -80px; */
  z-index: -1;
  position: relative;
  border-radius: 25px;
}

.download-section-cover {
  margin-top: 50px;
}

.inner-header-title {
  font-size: 20px;
  margin-bottom: 25px;
  margin-left: 40px;
}

.download-main-cover-wrapper {
  margin-top: 45px;
}

/* My Claim Status */
.claim-status-details-card {
  padding: 20px 25px;
  background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
  box-shadow: rgba(112, 211, 255, 0.55) 0px 3px 6px;
  border-radius: 20px;
  width: 83%;
  padding-bottom: 5px;
  background-image: url(./Assets/hospital-card-bg.png);

  background-size: cover;
  margin-left: 33px;
  margin-bottom: 25px;
}

.claim-accordion-main-box .accordion-button:not(.collapsed)::after {
  position: absolute;
  right: 80px;
  top: 35px;
}

.claim-accordion-main-box .accordion-button::after {
  position: absolute;
  right: 75px;
  top: 36px;
}

.test-content-list ul {
  list-style: none;
  padding: 0px;
}

.test-content-list ul li {
  margin-bottom: 9px;
  color: #363636;
  font-weight: 500;
  font-size: 15px;
}

.test-content-list ul li span {
  margin-left: 6px;
}

.test-track-claim a {
  display: flex;
}

.track_your_claim_toggle_box {
  display: flex;
  cursor: pointer;
}

.claim_data_point h5 {
  font-size: 16px;
  color: #000000b8;
}

.claim_title {
  margin-bottom: 20px;
}

.Track_your_claim_details_box {
  padding: 15px 25px;
}

.Track_your_claim_header {
  padding: 10px 22px;
  padding-bottom: 5px;
}

.Track_your_claim_header h4 {
  text-align: center;
  font-size: 19px;
  margin-top: 14px;
}

.claim_title h5 {
  font-size: 15px;
  text-transform: uppercase;
}

.Track_your_claim_details_box {
  padding: 15px;
}

.test-track-icon-box img {
  margin-right: 10px;
  width: 73%;
}

.test-track-claim a {
  display: flex;
  text-decoration: none;
  color: #021b8d;
  font-weight: 500;
  font-size: 14px;
}

.test-track-claim a p {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.test-track-claim {
  display: flex;
  justify-content: space-between;
}

.test-track-icon-box {
  width: 30px;
}

.test-main-header .policy-name {
  border-top-right-radius: 20px;
  font-weight: 500;
  box-shadow: rgba(112, 211, 255, 0.55) 0px 3px 6px;
  padding: 12px 15px 12px 25px;
  border-bottom-right-radius: 20px;
  margin-left: -25px;
  margin-bottom: 15px;
  font-size: 17px;
  width: 96%;
  background-color: white;
  border-top: 1px solid #0000000c;
  border-bottom: 1px solid #0000000c;
  border-right: 1px solid #0000000c;
}

.test-content-details .claim-number {
  color: #00b1ff;
  font-weight: 600;
  font-size: 15px;
  padding-bottom: 0px;
  margin-bottom: 10px;
}

.active-test-detail-wrapper {
  display: block;
  top: 90px;
  transition: 1.1s ease-in-out;
  margin-bottom: 45px;
}

.test-content-details .test-claim-number {
  color: #007e8b;
  font-weight: 600;
  font-size: 17px;
  border-bottom: 1px solid #70d3ff;
  margin-bottom: 15px;
  padding-bottom: 6px;
}

.test-content-list ul {
  border-bottom: 1px solid #70d3ff;
  padding-bottom: 5px;
}

li.claim-status-detail {
  font-weight: 700 !important;
}

span.paid-status {
  font-weight: 600;
  color: #007e8b;
}

.my-coverage-header-tabs ul li {
  margin: 5px 10px;
}

.claim-status-main-cover-wrapper {
  margin-top: 45px;
}

.claim-status-header-title {
  margin-bottom: 25px;
}

.arrow-img-box img {
  width: 27%;
}

.claims-status-box {
  display: flex;
}

.arrow-img-box img {
  width: 75%;
  height: 100%;
  margin-right: 10px;
}

.claims-status-box {
  display: flex;
  align-items: baseline;
  margin-bottom: 7px;
  min-width: 330px;
  /* margin: 0 auto; */
}

.claim-status-content-cover {
  margin-top: 50px;
}

.claim-status-history-date-box {
  margin-right: 25px;
}

.claim-status-history-date-box h5 {
  font-size: 17px;
}

.arrow-img-box {
  width: 60px;
  height: 25px;
  margin-right: 10px;
}

.claims-status-box-wrapper h4 {
  font-size: 17px;
  margin-bottom: 22px;
  text-align: center;
}

.claims-status-box-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 15px;
  border-top: 1px solid #70d3ff;
}

.claim-status-history-date-box h5 {
  font-size: 14px;
  margin-bottom: 0px;
  background-color: #7544c9cf;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 11px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid #00000038;
  color: white;
}

.claims-status {
  font-weight: 600;
  color: #007e8b;
}

.claim-line {
  position: absolute;
  width: 2px;
  height: 80%;
  top: 33px;
  background-color: black;
}

.claim-accordion-main-box .accordion-header button {
  padding: 0px;
}

.claim-accordion-main-box .accordion-item {
  border: none;
}

/* My Coverage */
.search-bar-box input {
  width: 550px;
  padding: 12px 15px;
  border: none;
  border-radius: 13px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #61616128;
  border: 0.5px solid #021b8d57;
  margin-top: 9px;
  position: relative;
}

.search-icon-box {
  position: absolute;
  right: 0;
}

.my-coverage-search-box {
  position: relative;
}

.search-icon-box img {
  width: 18px;
}

.search-icon-box {
  position: absolute;
  right: 14px;
  top: 18px;
  cursor: pointer;
}

.search-bar-box {
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.my-coverage-accordion-header {
  display: flex;
  align-items: baseline;
}

.coverage-accordian-icon-box {
  margin-right: 15px;
  width: 35px;
}

.coverage-accordian-icon-box img {
  width: 100%;
}

p.my-coverage-small-title {
  padding-bottom: 0px;
  margin-bottom: 0px;
  color: #007e8b;
  font-size: 15px;
  margin-top: 8px;
}

.my-coverage-content .accordion-item {
  background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
  box-shadow: rgb(112, 211, 255) 0px 3px 6px;
  border: 1px solid rgb(112, 211, 255);
  border-radius: 15px 15px 0px 0px !important;
  overflow: hidden;
  margin-top: -9px;
  padding: 14px 16px;
  padding-top: 9px;
}

.my-coverage-main-content-cover-wrapper {
  margin-top: 45px;
  margin-bottom: 45px;
}

.my-coverage-content .accordion-button:not(.collapsed) {
  background-color: transparent !important;
}

.claims-list-details p {
  padding-bottom: 0px;
  margin-bottom: 0px;
  color: #007e8b;
  font-weight: 300;
}

.claims-list-header h5 {
  font-size: 17px;
  width: 91%;
}

.claims-details-list .claims-list-header {
  display: flex;
  justify-content: space-between;
}

.list-info-btn-box img {
  width: 25px;
}

.claims-details-list {
  padding-bottom: 16px;
  margin-bottom: 22px;
  border-bottom: 1px solid #ffb125;
}

.accordion-item:first-of-type .accordion-button {
  box-shadow: none;
}

.my-coverage-accordion-header h5 {
  margin-bottom: 0px;
  color: #242424;
  font-size: 19px;
}

.opd-ipd-tab-box {
  padding: 10px 15px;
  margin: 0px 15px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
  border: none;
}

.words_text {
  font-size: 12px !important;
  color: #3d3ddb;
}

.opd-ipd-tab-box h3 {
  font-size: 20px;
  margin-bottom: 1px;
}

.active-tab {
  border: 1px solid black;
}

.claim-type-radio-btns {
  display: flex;
  justify-content: space-between;
}

.claim-type-radio-box label {
  border: 1px solid #0000002b;
  padding: 10px 15px;
  border-radius: 5px;
  border-radius: 9px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  width: 100%;
}

.claim-type-radio-box input {
  margin-right: 10px;
  position: absolute;
  right: 0;
}

label.claim-type-label {
  background-color: white;
}

.form-input-box .form-input select {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 9px 14px;
  background-color: white;
  border: none;
  border-radius: 7px;
  font-size: 15px;
  width: 100%;
  border: 1px solid #00000036;
}

.form-input-box.upload-document-box {
  /* border: 1px solid black; */
  padding: 10px 15px;
  border-radius: 10px;
  display: flex;
  border-radius: 10px;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  font-size: 14px;
  position: relative;
  padding-bottom: 0px;
}

small.file_type {
  font-size: 11px !important;
  background-color: #ffc107;
  color: white;
  font-weight: 700;
  padding: 3px;
  border-radius: 5px;
  margin-bottom: 12px;
}

.form-input small {
  text-transform: capitalize;
  font-size: 13px;
}

.intimate-claims-main-wrapper-cover {
  margin-top: 45px;
}

.intimate-claims-main-banner-img img {
  width: 450px;
}

.required-input {
  color: #c61515;
}

.test-footer-box li {
  list-style: none;
  display: flex;
  cursor: pointer;
}

/* Claim modal Status */
.test-all-details-wrapper {
  padding: 20px 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2fdaca93;
  width: 89%;
  margin-left: 15px;
  margin-top: -68px;
  border-radius: 30px;
}

.active-test-detail-wrapper {
  display: block;
  top: 90px;
  transition: 1.1s ease-in-out;
  margin-bottom: 45px;
}

.test-content-details .test-claim-number {
  color: #007e8b;
  font-weight: 600;
  font-size: 17px;
  border-bottom: 1px solid #70d3ff;
  margin-bottom: 15px;
  padding-bottom: 6px;
}

.claim-accordion-main-box .accordion-button:not(.collapsed) {
  background: none;
}

.test-content-list ul {
  border-bottom: 1px solid #70d3ff;
  padding-bottom: 5px;
}

li.claim-status-detail {
  font-weight: 700 !important;
}

span.paid-status {
  font-weight: 600;
  color: #007e8b;
}

.my-coverage-header-tabs ul li {
  margin: 5px 10px;
}

.claim_status_modal-content.modal-body {
  padding: 0px;
}

.claim_status_modal_header.modal-header {
  border: none;
  padding: 0px;
}

.claim_status_modal_header.modal-header button.btn-close {
  position: absolute;
  right: 23px;
  top: 32px;
  z-index: 1;
}

.modal-content {
  border-radius: 25px !important;
  outline: 0;
}

/* ========== hospital page style ======= */
.network-hospital-box-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
}

.sticky-form {
  position: sticky;
  bottom: 0px;
  /* width: 410px; */
}

.network-hospital-box-content p {
  color: #555555;
  margin-top: 15px;
  text-align: center;
  font-size: 15px;
}

.network-hospital-box-content img {
  width: 200px;
}

.hospital-text {
  display: flex;
  flex-direction: column;
}

.hospital-text input {
  box-shadow: 0px 3px 6px #61616128;
  border-radius: 15px;
  border: 0.5px solid #70d3ff;
  padding: 12px;
  width: 100%;
  /* margin-left: 60px; */
}

.main-banner-box img {
  width: 30%;
  margin: 0 auto;
}

.hospital-text label {
  position: absolute;
  left: 20px;
  top: -14px;
  background: white;
  padding: 0px 8px;
}

.hospital-text input::placeholder {
  text-align: left;
  font-size: 15px;
  padding-left: 20px;
}

.hospital-text img {
  width: 18px;
  position: absolute;
  right: 38px;
  top: 15px;
}

/* .network-hospital-form-content {
  margin-top: 35px;
} */
.hospital-option {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
}

.tab-box.disabled-tab {
  opacity: 0.6;
}

.network-hospital-form-content {
  position: relative;
  width: 300px;
}

.network-hospital-option-content {
  position: relative;
}

.hospital-location-container {
  display: contents;
}

.hospital-option select {
  padding: 12px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #61616128;
  border: 0.5px solid #70d3ff;
  border-radius: 15px;
  width: 80%;
  margin-left: 60px;
}

.hospital-option select {
  /* Hide the default arrow */
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.hospital-option select:optional {
  color: #949494;
  padding-left: 25px;
}

.hospital-option.city label {
  position: absolute;
  left: 85px;
  top: 67px;
  background: white;
  padding: 0px 12px;
}

.hospital-option.state label {
  position: absolute;
  left: 85px;
  top: -13px;
  background: white;
  padding: 0px 12px;
}

.network-hospital-search {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  width: 90%;
}

.network-hospital-search button {
  background: #3386aa;
  border: none;
  border-radius: 8px;
  color: white;
  padding: 8px 29px;
}

.network-hospital-search button {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.network-hospital-search span {
  margin-left: 10px;
  font-weight: 500;
}

.error-msg {
  margin-top: 10px;
  margin-left: 14px;
  margin-bottom: -15px;
}

.error-msg p {
  color: red;
  font-size: 14px;
  opacity: -1;
  margin-left: 58px;
  padding-bottom: 0px;
}

.error-msg-state {
  margin-top: -40px;
  margin-left: 14px;
  margin-bottom: 35px;
}

.error-msg-state p {
  color: red;
  font-size: 14px;
  /* opacity: -1; */
  margin-left: 58px;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  display: none;
}

/* .hospital-accordion-main-wrapper {
	padding: 0px 45px;
} */
.main-banner-box img {
  width: 30%;
  margin: 0 auto;
}

.main-banner-box {
  display: flex;
  width: 90%;
}

.hospital-not-found img {
  width: 56%;
  margin-left: 150px;
  margin-top: -25px;
}

.network-hospital-main-cover-content {
  width: 95%;
}

/* ======== Second Page Hospital style ===============*/
.network-hospital-total {
  display: flex;
  gap: 22px;
  margin-top: 25px;
}

.total-text span {
  font-size: 20px;
}

.total-text h4 {
  font-size: 20px;
}

.total-text {
  margin-top: 5px;
}

.network-hospital-search-option {
  margin-top: 15px;
}

.network-hospital-search-option input {
  background: #ffffff 0% 0%;
  box-shadow: 0px 3px 6px #61616128;
  border: 0.5px solid #70d3ff;
  border-radius: 15px;
  padding: 13px 30px;
  width: 101%;
}

.network-hospital-search-option img {
  margin-left: -40px;
  width: 18px;
}

.network-hospital-box-main-content {
  /* display: flex; */
  margin-top: 30px;
  background-image: url(./Assets/hospital-card-bg.png);
  /* background-image: url('https://img.freepik.com/premium-photo/abstract-background-design-template_980736-1177.jpg?w=740'); */
  box-shadow: 0px 3px 6px #70d3ff8c;
  border-radius: 10px;
  padding: 18px;
  margin-bottom: 30px;
  padding-bottom: 10px;
  max-height: 250px;
}

.hospital-box {
  display: flex;
  gap: 30px;
  position: relative;
  cursor: pointer;
  padding-bottom: 15px;
  border-bottom: 2px solid #b798cfd1;
  max-height: 57px;
}

.hospital-box .box-heading h4 {
  color: #86149b;
  font-weight: 600;
  font-size: 17px;
  margin-top: 5px;
  height: 44px;
  /* text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;  */
  width: 300px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.hospital-info-content {
  margin-top: 15px;
}

.hospital-location {
  display: flex;
  gap: 35px;
  align-items: center;
  margin-bottom: 14px;
}

.hospital-phone {
  display: flex;
  gap: 35px;
  /* margin-top: 10px; */
  align-items: center;
  margin-bottom: 12px;
}

.hospital-location-info p {
  color: #363636;
  margin-bottom: 0px;
  font-size: 15px;
}

.hospital-mail {
  display: flex;
  gap: 35px;
  margin-top: 12px;
  align-items: center;
}

/* .hospital-mail img {
  margin-top: 10px;
} */
.hospital-location-image img {
  width: 27px;
}

.hospital-direction {
  text-align: center;
  margin-top: 15px;
}

.hospital-direction a {
  color: #021b8d;
  font-size: 17px;
  font-weight: 500;
}

.network-hospital-search-option input::placeholder {
  text-align: left;
  font-size: 15px;
}

.modal-content .network-hospital-box-main-content {
  margin-bottom: 0px;
  border-bottom: 8px solid #6024afb0;
  border-radius: 24px;
}

.hospital_modal_header.modal-header {
  padding: 0;
}

.hospital_modal_header.modal-header button {
  z-index: 1;
  margin-right: 6px;
  margin-bottom: -19px;
}

.hospital_modal-content.modal-body {
  padding: 0px;
}

.hospital_modal-content.modal-body .network-hospital-box-main-content {
  margin-top: -18px !important;
  max-height: 100%;
}

.back-btn-box {
  margin-left: 26px;
  margin-bottom: 15px;
  display: none;
}

button.back-btn {
  background: #007e8b;
  color: white;
  padding: 5px 25px;
  border: none;
  border-radius: 5px;
}

.network-hospital-cover {
  margin: 0px 45px;
}

/* Network Hospital Accordion Styles */
.accordion-header .box-heading h4 {
  width: 290px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 16px;
  margin-left: 18px;
  margin-bottom: 0px;
}

.hospital-accordion-main-wrapper {
  margin-top: 25px;
}

.hospital-accordion-main-box .accordion-item {
  border: 1px solid rgba(0, 0, 0, 0.096);
  margin-bottom: 0px;
  border-bottom: 8px solid #6024afb0;
  border-radius: 24px !important;
  overflow: auto;
  background-image: url(./Assets/hospital-card-bg.png);
  background-size: cover;
}

.hospital-accordion-main-box .accordion-header {
  margin-bottom: 0px;
  height: 70px;
}

.hospital-accordion-main-box .accordion-header button {
  padding: 15px 24px;
  padding-bottom: 5px;
  background-color: transparent;
}

.hospital-accordion-main-box {
  margin-bottom: 15px;
}

.hospital-accordion-main-box .accordion-body {
  padding: 10px 20px;
  padding-top: 0px;
  margin-top: -10px;
}

.hospital-accordion-main-wrapper {
  margin-top: 35px !important;
}

.otp-msg {
  color: green;
}

.download-icon-box {
  cursor: pointer;
}

#offcanvasNavbar-expand-false {
  width: 22%;
  background-image: url("../src/Assets/side-menubar-bg.jpg");
  background-size: cover;
}

.nav-links {
  color: black;
  margin-bottom: 0px;
  text-decoration: none;
  font-size: 15px;
  padding: 6px 15px;
  border-radius: 7px;
  margin-bottom: 11px;
  display: flex;
  align-items: center;
  /* box-shadow: rgb(0 0 0 / 6%) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; */
}

.pdf-img-box img {
  width: 48%;
  margin: 0 auto;
  display: flex;
  margin-bottom: 10px;
}

.download-pdf-box h5 {
  font-size: 15px;
  text-align: center;
}

.pdf-download-icon-box img {
  width: 13%;
  cursor: pointer;
}

.download-pdf-box {
  position: relative;
}

.pdf-download-icon-box {
  text-align: center;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.download-pdf-box:hover .pdf-download-icon-box {
  opacity: 1;
}

.form-input-box.mb-3.upload-document-box input {
  padding: 7px;
  width: 100%;
  display: flex;
  border-radius: 10px;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  font-size: 14px;
}

.form-input-box.upload-document-box input {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

label.file_type small {
  padding: 2px 7px;
  border-radius: 5px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 11px;
  background-color: #ffc107;
  color: white;
  border: none;
}

.policytype-img-box {
  width: 60px;
  display: flex;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}

.policytype-img-box img {
  width: 62%;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.delete-btn-box {
  margin-left: 24px;
}

.delete-btn-box button {
  width: 45px;
  height: 45px;
  background: white;
  border-radius: 50%;
  border: 1px solid #6969691a;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.delete-btn-box button img {
  width: 50%;
}

.main-description {
  overflow: hidden;
  height: 26px;
}

/* Pagination */
ul.pagination-page-numbers {
  display: flex;
  list-style: none;
  justify-content: center;
  width: 100%;
  padding: 0;
}

ul.pagination-page-numbers li {
  border: 1px solid #6969690d;
  padding: 7px 15px;
  cursor: pointer;
  margin: 0px 3px;
  border-radius: 5px;
}

li.page_btn_active {
  background-color: blue;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

ul.pagination-page-numbers li.page_btn_disabled {
  color: #696969a6;
  opacity: 0.5;
}

li.prevBtn {
  border-radius: 5px;
  margin-right: 15px;
}

li.prevBtn {
  border-radius: 5px !important;
  margin-right: 10px !important;
  background-color: #3822b1d1;
  color: white;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

li.next_btn {
  border-radius: 5px !important;
  margin-left: 10px !important;
  /* border: 1px solid #000000ba !important; */
  background-color: #3822b1d1;
  color: white;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.pagination-main-wrapper {
  margin-top: 25px;
}

.selected_filename {
  position: absolute;
  top: 45px;
  right: 47px;
  width: 188px;
  padding: 0px 5px;
  background-color: white;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.hospital-text input::placeholder {
  padding: 10px 15px;
}

button.logout-btn {
  padding: 5px 18px;
  border-radius: 6px;
  background-color: white;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

button.logout-btn a {
  color: black;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

.login-details-cover h5 {
  font-size: 15px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-right: 15px;
  color: white;
}

.login-details-cover {
  display: flex;
}

/* button.logout-btn {
	position: absolute;
	right: 18px;
} */

a.navbar-brand {
  color: white;
}

.login-details-cover {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
  position: absolute;
  right: 15px;
  top: 9px;
}

/* .login-details-cover.has-logo{
  top: 20px;
} */
.backtoform {
  padding: 5px;
  background: #a69ed5;
  border: none;
  border-radius: 8px;
  color: white;
  padding-right: 15px;
  font-weight: bold;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

/*======================= Leaves Employee Page Starts  ==================================*/
.drop-down-main-cover .dropdown button {
  width: 100% !important;
  text-align: start;
}

.leaves-main-wrapper-cover {
  padding: 15px 35px;
}

.leaves-employee-tab-box-cover {
  /* margin: 30px 45px; */
  margin-top: 45px;
}

.leaves-employee-count-box h3 {
  margin-bottom: 0px;
}

.leaves-employee-count-box {
  padding: 15px 25px;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-image: url("https://img.freepik.com/free-vector/abstract-geometric-wireframe-background_52683-59421.jpg?w=740&t=st=1698215497~exp=1698216097~hmac=aa5c60aaf975f3cb86abf3402b575a62b5b22f7b350b13227ea7982c65249773");
  background-size: cover;
  background-position: center;
}

.leaves-employee-title h3 {
  color: #696969e6;
  font-size: 24px;
}

.leaves-main-wrapper-cover {
  /* padding: 15px 35px; */
  /* background-color: #69696903; */
  margin-top: -15px;
  padding-top: 30px;
  min-height: 100vh;
  background: #fffafa;
}

.leave_employee_input {
  display: flex;
  flex-direction: column;
}

.react-datepicker__input-container input {
  width: 95%;
  padding: 7px 14px;
  border-radius: 5px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.leave_employee_modal.modal-body {
  padding: 30px 25px;
  padding-bottom: 15px;
}

.leave_employee_modal.modal-header button.btn-close {
  position: absolute;
  right: 28px;
  top: 28px;
  background-color: #ddd8ce;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  z-index: 1;
  color: white;
  border: 1px solid #0000002e;
}

.leave_employee_modal.modal-header {
  padding: 0px;
  border: none;
}

.leave_employee_input select {
  padding: 10px 14px;
  width: 97%;
  border-radius: 9px;
  font-size: 14px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgb(60 64 67 / 5%) 0px 2px 6px 2px;
}

.leave_employee_input textarea {
  padding: 10px 14px;
  width: 97%;
  border-radius: 9px;
  font-size: 14px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgb(60 64 67 / 5%) 0px 2px 6px 2px;
}

.leave_employee_input input {
  padding: 10px 14px;
  width: 97%;
  border-radius: 9px;
  font-size: 14px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgb(60 64 67 / 5%) 0px 2px 6px 2px;
}

.leave_employee_input label {
  font-size: 15px;
}

.submit-btn-box {
  display: flex;
  justify-content: center;
  margin: 24px 0px;
  margin-bottom: 13px;
}

form.leave_employee_form h4 {
  font-size: 18px;
}

.custom-stepper-step {
  /* Add your custom styles here */
  color: blue;
  font-weight: bold;
}

.punch-det {
  background-color: white;
  border: 2px solid white;
  border-radius: 15px;
  margin: 1px 0 20px;
  padding: 10px 15px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(3px);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.punch-info .punch-hours {
  background-color: white;
  border: 5px solid #e5dddd;
  font-size: 18px;
  height: 120px;
  width: 120px;
  margin: 0 auto;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.text-muted-dim {
  color: #000000 !important;
  font-size: 15px;
  margin-left: 25px;
}

span.clock-icon-box img {
  width: 5%;
  margin-right: 5px;
}

.attendence-employee-box {
  padding: 15px 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 15px;
  width: 95%;
  margin: 0 auto;
  background-color: white;
  background-image: url("./Assets/attendence-card-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.attendence-employee-content-cover {
  margin-top: -15px;
  padding-top: 25px;
  background-color: #ffffffde;
}

.punch-btn-section {
  text-align: center;
  margin: 0 0 20px;
  margin-top: 18px;
}

.punch-btn-section .punch-btn {
  font-size: 15px;
  font-weight: 600;
  max-width: 100%;
  padding: 8px 40px;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.btn-primary-custom {
  background-color: #ff9b44 !important;
  border: 1px solid #ff9b44 !important;
  color: #fff !important;
}

.punch-det p {
  color: #727272;
  font-size: 14px;
  margin: 0;
}

.punch-det h6 {
  line-height: 20px;
  margin: 0;
  font-size: 17px;
}

.attendence-employee-box h4 {
  margin-bottom: 15px;
  font-size: 20px;
}

.stats-box {
  background-color: white;
  border: 1px solid #e3e3e3;
  margin: 0 0 10px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.download-forms-banner-img.circular-banner-img {
  z-index: 1;
  position: relative;
}

.download-forms-banner-img {
  z-index: 1;
  position: relative;
}

.stats-box p {
  margin: 0;
  font-size: 12px;
}

.stats-info p {
  font-size: 12px;
  margin: 0 0 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stats-info {
  margin-bottom: 22px;
  border: 1px solid #6969691f;
  padding: 7px 5px;
  border-radius: 5px;
}

.custom-progress-bar {
  height: 8px !important;
}

.res-activity-list {
  /* height: 328px; */
  list-style-type: none;
  overflow-y: auto;
  position: relative;
  margin: 0;
  padding: 0 0 0 30px;
}

.leave_employee_modal.modal-header button {
  position: absolute;
  right: 0;
  top: 20px;
  right: 20px;
  border-radius: 9px;
  background: #db60e0;
  color: white;
  padding: 3px 12px;
  font-weight: bold;
  font-size: 18px;
  z-index: 1;
}

.res-activity-list li {
  margin: 0 0 15px;
  position: relative;
}

.res-activity-list li:before {
  content: "";
  width: 10px;
  height: 10px;
  border: 2px solid #ff9b44;
  z-index: 2;
  background: #fff;
  border-radius: 100%;
  margin: 0 0 0 15px;
  position: absolute;
  top: 0;
  left: -45px;
}

.res-activity-list p {
  font-size: 13px;
  margin: 0;
}

.res-activity-time {
  color: #000000e3;
  font-weight: 600;
  font-size: 12px;
}

.res-activity-list:after {
  content: "";
  border: 1px solid #3d3c3c2f;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4px;
  height: 100%;
}

.attendence-employee-box h4 {
  font-size: 19px;
  margin-bottom: 22px;
}

.attendence-employee-data-cover {
  margin-top: 24px;
  margin-bottom: 25px;
}

.custom-datatable {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.even-row {
  background-color: #f7f7f7;
}

.odd-row {
  background-color: #ffffff;
}

.custom-header-cell {
  font-weight: bold;
}

.datatable-header-component {
  display: flex;
}

.search-btn-box button {
  padding: 6px 12px;
  margin-left: 15px;
  border-radius: 7px;
  background-color: #eaf7ff;
  color: black;
  font-size: 14px;
  border: none;
  border: 1px solid #342f2f26;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.search-input-box input.form-control {
  width: 300px;
  font-size: 14px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.leaves-employee-count-box h5 {
  font-size: 17px;
  color: #000000a6;
}

.submit-btn-box button {
  padding: 7px 20px;
  border-radius: 9px;
  color: white;
  font-weight: 500;
  background-image: linear-gradient(to right top,
      #639ef7,
      #32bae9,
      #74cbcd,
      #b4d5c3,
      #dddfd8);
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.attendence-employee-box.attendence-list-box {
  height: 412px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.attendence-list-box::-webkit-scrollbar {
  width: 12px;
}

li.download-form-list.circular-pdf-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.download-circular-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 12px;
}

.circular_description h5 {
  font-size: 17px;
}

.circular_description p {
  font-size: 14px;
  margin-bottom: 0px;
}

.circular_description {
  width: 100%;
}

.download-forms-banner-img.circular-banner-img img {
  width: 37%;
  margin-top: 0px;
}

p.register-link {
  font-size: 14px;
  margin-top: 15px;
}

p.register-link a {
  margin-left: 10px;
}

h3.text-center.header-Text {
  z-index: 2;
  position: relative;
}

/* Handle */
.attendence-list-box::-webkit-scrollbar-thumb {
  background: #888;
  /* Change the color to your desired scrollbar color */
  border-radius: 6px;
}

/* Handle on hover */
.attendence-list-box::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Change the color to your desired hover color */
}

.data-not-found-icon img {
  width: 32%;
  margin: 0 auto;
  display: flex;
}

.main-wellness-product-box {
  padding: 20px;
  width: 95%;
  border-radius: 15px;
  padding-bottom: 3px !important;
  margin: 0 auto;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  background: #ffffff 0% 0%;
}

.main-wellness-content-box p {
  font-size: 14px;
  margin-right: 25px;
  margin-bottom: 10px !important;
  color: dimgray;
}

.main-wellness-img-box img {
  width: 40px;
  margin-bottom: 5px;
}

.main-wellness-content-box span a {
  color: black;
  text-decoration: none;
  background-color: #19b9ad;
  padding: 5px 15px;
  margin-top: 10px;
  font-size: 13px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  color: white;
}

.wellness_main_cover_wrapper h3 {
  font-size: 30px;
  font-weight: 800;
  color: #19b9ad;
}

.main-wellness-content-box h5 {
  font-size: 17px;
  margin-top: 5px;
  font-weight: 600;
}

.main-wellness-content-box span {
  display: flex;
  justify-content: center;
  margin-bottom: -3px;
}

/* Redesign starts ----------------------- */
.claims-features-main-cover-wrapper a {
  display: flex;
  margin-bottom: 15px;
  font-family: "Lora";
  font-weight: 600;
}

.members-covered-cards-wrapper {
  margin-top: 42px;
}

.hero-title-typography-content h2 {
  font-weight: 200;
  font-family: "Lora", serif;
}

.hospital-not-found {
  margin-left: 145px;
  margin-right: -50px;
  margin-top: 56px;
}

.claims-features-main-cover-wrapper {
  border: 1px solid #6969694f;
  width: 75%;
  margin: 0 auto;
  border-radius: 15px;
  padding: 15px 10px;
  background: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.custom-container {
  max-width: 1150px;
}


.claims-tabs-section-cover-wrapper {
  margin-top: 45px;
  overflow: hidden;
  width: 100%;
}

.highlight-txt {
  font-weight: bold;
  color: #5782cc;
  font-family: "Lora", serif;
}

.gmc-main-box-content {
  width: 90%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  padding: 45px 0px;
  padding-bottom: 10px;
}

/* .hospital-accordion-main-wrapper {
	width: 90%;
} */
/* Wellness Benefits starts --------------------------------------- */
.hero-content-box-cover {
  padding: 0px 50px;
}

button.clear-filter-btn {
  background: transparent;
  color: black;
  border: 1px solid #69696970;
  border-radius: 9px;
  padding: 8px 15px;
  font-size: 15px;
  font-weight: 600;
  background: white;
}

button.clear-filter-btn span img {
  width: 16px;
  margin-left: -9px;
  margin-right: 7px;
}

a.wellness-card-link {
  color: inherit;
  text-decoration: none;
}

.employee-benefit-wellness-box {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* padding-top: 38px; */
  padding-bottom: 0px;
  border-radius: 10px;
  background: #fff;
  padding: 11px 0;
  padding-bottom: 0;
  height: 100%;
}

.employee-benefit-wellness-heading-line {
  height: 2px;
  background: red;
  background-image: none;
  width: 42%;
  margin-top: 5px;
  margin-bottom: 11px;
  background-image: linear-gradient(106deg, #2be1b3 7%, #4d65d3 96%);
}

.employee-benefit-wellness-btn a {
  padding: 3px 25px;
  font-size: 14px;
  color: #fff;
  background-image: linear-gradient(106deg, #2be1b3 7%, #4d65d3 96%);
  border-radius: 10px 0px;
  padding-bottom: 3px;
}

.employee-benefit-wellness-box p {
  font-size: 12px;
  text-wrap: balance;
  width: 250px;
  padding-bottom: 10px;
}

.employee-benefit-wellness-heading span {
  font-weight: bold;
  font-family: "Lora", serif;
}

.employee-benefit-wellness-heading span a {
  text-decoration: none;
  color: #000000e3;
}

.employee-benefit-wellness-box-icon img {
  width: 80%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-left: 15px;
}

.employee-benefit-wellness-btn {
  display: flex;
  justify-content: right;
  position: absolute;
  right: 0;
  bottom: 0;
}

.employee-benefit-wellness-cover {
  /* margin-bottom: 40px; */
  height: 100%;
  position: relative;
}

.benefits-title-header {
  padding: 45px 0px;
}

.benefits-title-header h2 {
  font-weight: bold;
  font-family: "Lora", serif;
}

.benefits-title-header h3 {
  font-size: 25px;
  font-family: "Lora", serif;
  color: dimgray;
}

.employee-benefit-wellness-image img {
  margin-left: -15px;
}

/* Hero Section Starts---------------------------------- */
.hero-title-typography-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

span.logout-icons {
  margin-right: 6px;
}

.main-header-cover {
  width: 90%;
  margin: 0 auto;
  border-radius: 15px;
  margin-top: 35px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.main-header-cover nav {
  border-radius: 9px;
  padding: 6px 15px;
}

.hero-content-cover {
  padding: 65px 0px;
  padding-bottom: 35px;
}

.network-hospital-main-cover-wrapper {
  padding-top: 45px;
}

.hero-banner-cover {
  display: flex;
  justify-content: end;
}

.hero-banner-cover img {
  width: 55%;
  display: flex;
}

.main-header-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-header-cover.nav-sticky.active nav {
  border-radius: 9px;
  width: 95%;
  padding: 10px 15px;
}

.main-header-cover.nav-sticky .header-navbar-collapse {
  position: absolute;
  right: 0px;
  width: 350px;
}

.insurance-product-cover-box {
  margin-top: 20px;
}

.insurance-product-main-cover-wrapper {
  margin: 45px 0px;
  margin-bottom: 35px;
}

.insurance-product-title-cover h5 {
  font-size: 17px;
  margin-top: 20px;
  color: dimgray;
}

.insurance-product-box {
  /* border: 2px dashed #9821b6b0; */
  background: white;
  border-radius: 15px;
  padding: 15px;
  padding-top: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  width: 95%;
  /* margin: 0 auto; */
  margin-top: 10px;
  margin-bottom: 20px;
}

.product-icon-box {
  position: absolute;
  right: 13px;
  bottom: 7px;
}

.product-icon-box img {
  width: 70px;
}

.insurance-product-title-cover h2 {
  font-family: "Lora", serif;
}

a.get-quotes-btn {
  border-radius: 26px;
  padding: 4px 13px;
  font-size: 12px;
  font-weight: 700;
  color: darkslateblue;
  background-color: aliceblue;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  text-decoration: none;
}

.packages-section-main-cover-wrapper {
  width: 90%;
  margin: 0 auto;
}

.insurance-product-box h5 span {
  display: block;
  /* font-family: "Lora", serif; */
}

.insurance-product-box h5 {
  font-size: 15px;
  margin-bottom: 15px;
  margin-top: 5px;
  /* font-family: "Lora", serif; */
}

.view-document-btn button {
  border-radius: 5px;
  padding: 7px 17px;
  font-size: 14px;
  border: none;
  background-color: darkorchid;
  color: white;
  font-weight: 600;
  padding-left: 9px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.view-document-btn button span {
  margin-right: 8px;
}

.doc-listview-main-wrapper {
  padding: 15px 0px;
  background: white;
}

.existing-list-card h5 {
  font-size: 14px;
}

.existing-claims-card {
  border: 1px solid #9a4bb478;
  border-radius: 11px;
  padding: 9px 15px;
  width: 95%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 0 auto;
  margin-bottom: 15px;
}

.full-loader-main-cover-wrapper {
  position: absolute;
  width: 98vw;
  height: 100vw;
  left: 0;
  z-index: 99999;
  background: #f0efefab;
  top: 0px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-loader-main-cover-wrapper .loadingData-animation {
  width: 400px;
}

span.highlight-red-txt {
  color: #e51818;
  font-weight: 500;
}

.existing-claims-card-cover-wrapper {
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}

button.add-new-btn {
  padding: 5px 10px;
  border-radius: 5px;
  background: darkslateblue;
  color: white;
  font-weight: 500;
  border: none;
  font-size: 13px;
}

button.add-new-btn svg {
  font-size: 19px;
}
button.generate-otp-btn {
  padding: 7px 19px;
  border-radius: 5px;
  background: #071170;
  border: none;
  color: white;
  font-size: 14px;
}
button.disable-btn {
  opacity: 0.3;
}

/* Google Translator ------------------------------ */
div#goog-gt-vt {
  display: none;
}

.skiptranslate.goog-te-gadget {
  display: block !important;
}


.skiptranslate {
  display: none;
}

iframe.skiptranslate {
  display: block;
}

.goog-te-gadget {
  display: block !important;
  font-size: 0 !important;
}

body {
  top: 0 !important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget-simple img {
  display: none;
}

.goog-te-gadget-simple {
  padding: 5px 10px;
  border-radius: 5px;
  padding-right: 0px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}


.goog-te-gadget-simple {
  position: absolute;
  right: 70px;
}

.home-section-banner {
  height: 200px;
  background-size: 100%;
}

/* .hero-section-main-cover-wrapper {
	padding: 15px 0px;
} */
/* Responsive Styles */
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .form-input-box.mb-3.upload-document-box .form-input {
    position: relative;
  }

  .login-banner-box img {
    width: 75%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .hero-banner-cover {
    justify-content: center;
    margin-top: 20px;
  }
  .hero-banner-cover img {
    width: 75%;
    display: flex;
    justify-content: center;
  }
  span.Brand-title {
    margin-left: 0px;
    display: block;
    text-wrap: balance;
    font-size: 13px;
  }

  .main-header-content {
    align-items: start;
  }

  .login-details-cover h5 {
    font-size: 13px;
  }

  .selected_filename {
    position: absolute;
    top: 70px;
    right: 17px;
    width: 188px;
    padding: 0px 5px;
    background-color: white;
  }

  .register-left-background-cover img {
    width: 58%;
    margin-bottom: 0px;
  }

  .form-main-cover-wrapper {
    padding: 25px 34px;
    width: 100%;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .login-form-main-cover {
    height: auto;
    margin-top: 20px;
    overflow-y: scroll;
    padding: 0px 15px;
    background-size: contain;
}
  .broker-logo-box {
    background: white;
}
  .login-content-box-cover form {
    width: auto;
    border-radius: 0px;
  }
  .broker-logo-box img {
    width: 70%;
    margin: 0 auto;
    display: flex;
  }

  .login-form-cover {
    width: 100%;
    background-size: cover;
  }

  .login-form-content-wrapper {
    height: 250px;
  }

  .test-main-header .policy-name {
    margin-left: -19px;
  }

  .test-footer-box li p {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .claim-status-details-card {
    padding: 20px 18px;
    width: 100%;
    background-size: 100%;
  }

  .test-track-claim a p {
    font-size: 12px;
  }

  .main-header-content {
    flex-direction: column;
  }

  .test-all-details-wrapper {
    padding: 20px 20px;
  }

  .test-track-icon-box img {
    margin-right: 4px;
    width: 100%;
  }

  .test-track-icon-box {
    width: 17px;
    margin-right: 5px;
  }

  #offcanvasNavbar-expand-false {
    width: 60%;
  }

  .Btn {
    width: 98% !important;
  }

  .text {
    font-size: 17px;
  }

  .member-card-header-details h4 {
    margin-bottom: 14px;
  }

  .members-details-card {
    padding: 15px 20px;
  }

  .download-pdf-box {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .pdf-download-icon-box {
    top: 42%;
    left: 17%;
  }

  .contact-us-details-box {
    padding: 15px;
    padding-top: 35px;
  }

  .email-text p {
    font-weight: 500;
    font-size: 14px;
  }

  .email-image img {
    width: 15px;
  }

  .email-text {
    margin-left: 10px;
    margin-top: 3px;
  }

  .phone-image img {
    width: 15px;
  }

  .user-image img {
    width: 15px;
  }

  .policy-details-content-box {
    width: 85%;
    margin: 0 auto;
    margin-left: auto;
    margin-left: 35px;
  }

  .policy-details-text-box {
    padding: 20px 10px 10px 20px;
  }

  .policy-details-text-box p {
    margin-bottom: 3px;
  }

  .policy-details-text-box h4 {
    font-size: 15px;
  }

  .download-forms-banner-img img {
    margin-top: 0px;
  }

  h2.accordion-header button {
    padding: 10px;
  }

  .my-coverage-accordion-header h5 {
    font-size: 16px;
  }

  .my-coverage-content .accordion-body {
    padding: 10px;
  }

  .claims-list-header h5 {
    font-size: 16px;
  }

  .claims-list-details p {
    font-size: 15px;
  }

  .search-bar-box {
    width: 91%;
  }

  .search-bar-box input {
    width: 100%;
  }

  .intimate-claims-main-banner-img img {
    width: 85%;
    margin: 0 auto;
    display: flex;
  }

  label.form-label.upload-label {
    display: flex;
    flex-direction: column;
  }

  small.file_type {
    width: 66%;
  }

  .form-input-box.upload-document-box {
    padding: 5px;
  }

  .network-hospital-cover {
    margin: 0 10px;
  }

  .hospital-box .box-heading h4 {
    height: 36px;
    width: 240px;
  }

  .network-hospital-box-main-content {
    margin-bottom: 0px;
  }

  ul.pagination-page-numbers {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    gap: 8px;
  }

  .hospital-option {
    margin-bottom: 20px;
    width: 96%;
  }

  .hospital-text input {
    width: 96%;
    margin-left: 2px;
    margin-bottom: 10px;
  }

  .hospital-text label {
    left: 18px;
    top: -14px;
    padding: 0px 6px;
  }

  /* .hospital-text input {
    padding: 12px 0px;
  } */
  .gmc-main-box-content {
    width: 97%;
    margin: 0 auto;
  }

  .main-banner-box img {
    width: 65%;
    margin-bottom: 5px;
    margin: 0 auto !important;
    display: flex !important;
  }

  .right-banner-box {
    margin-top: 40px;
  }

  .policy-type-tab-main-cover {
    flex-wrap: wrap;
  }

  .tab-box {
    margin-bottom: 20px;
  }

  .claim-status-details-card {
    margin-left: 0px;
  }

  .test-all-details-wrapper {
    margin-left: -7px;
  }

  .test-detail-main-page-cover {
    width: 96vw;
  }

  .claim-accordion-main-box .accordion-button::after {
    right: 12px;
  }

  .claims-status-box {
    margin-left: 30px;
    min-width: 300px;
  }

  .hospital-not-found img {
    margin: 0 auto;
    display: flex;
  }

  button.logout-btn {
    position: relative;
    right: 0;
    width: 50%;
    /* margin: 0 auto; */
  }

  .accordion-header .box-heading h4 {
    width: 220px;
    -webkit-line-clamp: 3;
    overflow: hidden;
    font-size: 15px;
  }

  .hospital-accordion-main-box .accordion-header button {
    padding: 15px 20px;
  }

  .hospital-accordion-main-box .accordion-item {
    width: 100%;
    margin: 0 auto;
    margin-left: auto;
    margin-left: 10px;
  }

  .leaves-main-wrapper-cover {
    padding: 25px 0px;
  }

  .search-input-box input.form-control {
    width: auto;
  }

  .search-btn-box button {
    display: flex;
  }

  .leaves-employee-count-box {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 18px;
  }

  .attendence-employee-box {
    margin-bottom: 25px;
  }

  .download-forms-banner-img.circular-banner-img img {
    width: 57%;
    margin-top: 0px;
    margin-bottom: 15px;
  }

  /* New Responsive-------------- */
  .claims-features-main-cover-wrapper {
    width: 90%;
  }

  .main-header-cover nav {
    width: 95%;
    margin: 0 auto;
  }

  .policy-details-cover {
    margin-top: 35px;
  }

  .employee-benefit-wellness-box p {
    font-size: 12px;
    padding-bottom: 15px;
  }

  .employee-benefit-wellness-image {
    margin-bottom: 35px;
  }

  .members-download-card {
    width: 80%;
    margin: 0 auto;
  }

  .members-covered-cards-wrapper {
    margin-top: 25px;
  }

  .gmc-main-box-content {
    flex-direction: column;
  }

  .backtoform {
    margin-bottom: 15px;
    margin-left: 15px;
  }

  .main-header-cover {
    box-shadow: none;
    width: 100%;
  }

  .goog-te-gadget-simple {
    right: 12px;
  }
}

/* Animation --------------------- */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}